import clsx from "clsx";
import { Logo } from "../../shared/Logo/Logo";
import styles from "./Footer.module.scss";
import { useContext } from "react";
import { MainContext } from "../../app/providers/MainContext";

const Footer = () => {
  const { currentPage } = useContext(MainContext);

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div
          onClick={() => {
            if (currentPage === "home") {
              window.fullpage_api.moveTo(1);
            } else {
              location.href = "/";
            }
          }}
          className={clsx(styles.footerLogo, "reveal")}
        >
          <Logo logoType='footer' />
        </div>
        <div className={styles.footerCols}>
          <div className={clsx(styles.col, "reveal")}>
            <div className={styles.head}>Contact</div>
            <div className={styles.body}>
              <p>contact@prophetbots.com</p>
            </div>
          </div>
          <div className={clsx(styles.col, "reveal")}>
            <div className={styles.head}>Links</div>
            <div className={styles.body}>
              <a href='https://dashboard.prophetbots.io/'>Dashboard</a>
              <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xa07674a55c6278244cc2113fbc9d1769b6dfca48'>
                Buy $PROPHET
              </a>
              <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xa07674a55c6278244cc2113fbc9d1769b6dfca48'>
                Dextools
              </a>
            </div>
          </div>

          <div className={clsx(styles.col, "reveal")}>
            <div className={styles.head}>Socials</div>
            <div className={styles.body}>
              <a href='https://twitter.com/ProphetBots'>Twitter</a>
              <a
                href='https://t.me/+n2tiEUcl4d1hMGI0
'
              >
                Telegram Group
              </a>
              <a href='https://t.me/+TbWSvYGzqaIyOTM0'>Telegram Bot</a>
            </div>
          </div>
          <div className={clsx(styles.col, "reveal")}>
            <div className={styles.head}>Resources</div>
            <div className={styles.body}>
              <a href='https://prophetbots.gitbook.io/prophetbots/'>
                Gitbook Documentation
              </a>

              <a href='/revenue-share'>Revenue Share</a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
