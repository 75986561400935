import { useContext, useEffect, useState } from "react";
import clsx from "clsx";

import { Button } from "../../shared/Button/Button";
import { Logo } from "../../shared/Logo/Logo";
import styles from "./Header.module.scss";
import { MainContext } from "../../app/providers/MainContext";
import { headerLinks } from "../../data";

const Header = () => {
  const [menuActive, setMenuActive] = useState<boolean>(false);

  const { pageLoaded, setPageLoaded, currentPage, setBlockedScroll } =
    useContext(MainContext);

  const toScreen = (id: string) => {
    const screen = document.querySelector<HTMLDataElement>(id);

    const screenId = Number(screen?.dataset.screen);

    if (screenId) {
      setBlockedScroll(false);
      window.fullpage_api.setAllowScrolling(true, "up");
      window.fullpage_api.setAllowScrolling(true, "down");
      window.fullpage_api.moveTo(screenId + 1);
    }
  };

  useEffect(() => {
    setTimeout(() => setPageLoaded(true), 0);
  }, []);

  return (
    <header
      className={clsx(
        styles.header,
        pageLoaded && "animated",
        menuActive && styles.filled
      )}
    >
      <div className='container'>
        <div
          onClick={() => {
            if (currentPage === "home") {
              window.fullpage_api.moveTo(1);
            } else {
              location.href = `/`;
            }
          }}
          className={clsx(styles.logo, "reveal")}
        >
          <Logo />
        </div>
        <div
          onClick={() =>
            menuActive ? setMenuActive(false) : setMenuActive(true)
          }
          className={clsx(
            styles.menuBtn,
            menuActive && styles.active,
            "reveal"
          )}
        >
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M3.19995 21.3335L29.5999 21.3335'
              stroke='white'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M3.19995 11.7334L29.5999 11.7334'
              stroke='white'
              stroke-width='1.5'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>

          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M24.9998 7L6.99976 25'
              stroke='white'
              stroke-width='1.45833'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
            <path
              d='M24.9998 25L6.99976 7'
              stroke='white'
              stroke-width='1.45833'
              stroke-linecap='round'
              stroke-linejoin='round'
            />
          </svg>
        </div>
        <div>
          <div className='button-group reveal'>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xa07674a55c6278244cc2113fbc9d1769b6dfca48'>
              <Button>
                Trade $PROPHET
                <img src='/images/icons/mini-logo.svg' alt='' />
              </Button>
            </a>
            <a href='https://t.me/+TbWSvYGzqaIyOTM0'>
              <Button colorType='two'>
                Join Telegram
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.2641 7.8906L13.9768 11.7526C13.0691 14.4756 12.6153 15.8371 11.9498 16.224C11.3167 16.592 10.5347 16.592 9.90162 16.224C9.23612 15.8371 8.78229 14.4756 7.87463 11.7526C7.7289 11.3154 7.65602 11.0968 7.5337 10.9141C7.41515 10.737 7.26303 10.5849 7.08595 10.4663C6.90322 10.344 6.68462 10.2711 6.2474 10.1254C3.52443 9.21771 2.16294 8.76388 1.77604 8.09837C1.40799 7.46527 1.40799 6.68334 1.77604 6.05024C2.16294 5.38473 3.52443 4.9309 6.2474 4.02324L10.1094 2.73591C13.4832 1.61131 15.1701 1.04901 16.0605 1.93947C16.951 2.82992 16.3887 4.51681 15.2641 7.8906ZM9.78426 8.17663C9.56581 7.95575 9.56779 7.5996 9.78867 7.38115L12.9466 4.25806C13.1675 4.03961 13.5236 4.04159 13.7421 4.26247C13.9605 4.48336 13.9585 4.83951 13.7376 5.05796L10.5797 8.18104C10.3589 8.39949 10.0027 8.39752 9.78426 8.17663Z'
                    fill='#000104'
                  />
                </svg>
              </Button>
            </a>
          </div>
        </div>
        <nav className='reveal'>
          <ul>
            <li className={styles.shortLink}>
              <a href='https://dashboard.prophetbots.io/'>Dashboard</a>
            </li>
            <li className={styles.shortLink}>
              <a href='/revenue-share'>Revenue Share</a>
            </li>
            <li className={styles.shortLink}>
              <a href='https://prophetbots.gitbook.io/prophetbots/'>
                <img src='/images/icons/gitbook.svg' alt='' />
                Gitbook
              </a>
            </li>
            <li className={styles.shortLink}>
              <a href='https://twitter.com/prophetbots'>
                <img src='/images/icons/twitter.svg' alt='' />
                Twitter
              </a>
            </li>
          </ul>
        </nav>
      </div>
      <menu className={clsx(styles.menu, menuActive && styles.active)}>
        <ul>
          <li>
            <a href='/'>Home</a>
          </li>
          {headerLinks.map((link) => (
            <li
              onClick={(ev) => {
                setMenuActive(false);
                if (currentPage !== "home") {
                  ev.preventDefault();
                  location.href = `/`;
                } else {
                  if (link.href[0] === "#") {
                    ev.preventDefault();
                  }
                  toScreen(link.href);
                }
              }}
            >
              <a href={link.href}>{link.title}</a>
            </li>
          ))}
        </ul>
        <div className={styles.group}>
          <div className='button-group'>
            <a href='https://www.dextools.io/app/en/ether/pair-explorer/0xa07674a55c6278244cc2113fbc9d1769b6dfca48'>
              <Button>
                Trade $PROPHET
                <img src='/images/icons/mini-logo.svg' alt='' />
              </Button>
            </a>
            <a href='https://t.me/+TbWSvYGzqaIyOTM0'>
              <Button colorType='two'>
                Join Telegram
                <svg
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M15.2641 7.8906L13.9768 11.7526C13.0691 14.4756 12.6153 15.8371 11.9498 16.224C11.3167 16.592 10.5347 16.592 9.90162 16.224C9.23612 15.8371 8.78229 14.4756 7.87463 11.7526C7.7289 11.3154 7.65602 11.0968 7.5337 10.9141C7.41515 10.737 7.26303 10.5849 7.08595 10.4663C6.90322 10.344 6.68462 10.2711 6.2474 10.1254C3.52443 9.21771 2.16294 8.76388 1.77604 8.09837C1.40799 7.46527 1.40799 6.68334 1.77604 6.05024C2.16294 5.38473 3.52443 4.9309 6.2474 4.02324L10.1094 2.73591C13.4832 1.61131 15.1701 1.04901 16.0605 1.93947C16.951 2.82992 16.3887 4.51681 15.2641 7.8906ZM9.78426 8.17663C9.56581 7.95575 9.56779 7.5996 9.78867 7.38115L12.9466 4.25806C13.1675 4.03961 13.5236 4.04159 13.7421 4.26247C13.9605 4.48336 13.9585 4.83951 13.7376 5.05796L10.5797 8.18104C10.3589 8.39949 10.0027 8.39752 9.78426 8.17663Z'
                    fill='#000104'
                  />
                </svg>
              </Button>
            </a>
          </div>
        </div>
      </menu>
    </header>
  );
};

export default Header;
