export const headerLinks = [
  {
    title: "Dashboard",
    href: "https://dashboard.prophetbots.io/",
  },
  {
    title: "Revenue Share",
    href: "/revenue-share",
  },
  {
    title: "Claim",
    href: "/claim",
  },
  {
    title: "Gitbook",
    href: "https://prophetbots.gitbook.io/prophetbots/",
  },
  {
    title: "Twitter",
    href: "https://twitter.com/prophetbots",
  },
];

export const alphaPhase = [
  {
    title: "Marketing",
    icon: "/images/Roadmap/4.svg",
  },
  {
    title: "$PROPHET Launch",
    icon: "/images/Roadmap/1.svg",
  },
  {
    title: "ProphetBot Live including feature",
    icon: "/images/Roadmap/5.svg",
  },
  {
    title: "Rich tiered access system",
    icon: "/images/Roadmap/3.svg",
  },
  {
    title: "Image Generation- ProphetBots Multi",
    icon: "/images/Roadmap/9.svg",
  },
  {
    title: "Revenue Generation for holders",
    icon: "/images/Roadmap/6.svg",
  },
  {
    title: "Partnerships",
    icon: "/images/Roadmap/11.svg",
  },
  {
    title: "Wallet system",
    icon: "/images/Roadmap/10.svg",
  },
  {
    title: "Prophet Bot Sniper",
    icon: "/images/Roadmap/7.svg",
  },
];

export const Partnerships = [
  {
    title: "Marketing",
    icon: "/images/Roadmap/4.svg",
  },

  {
    title: "Prophecy Phase",
    icon: "/images/Roadmap/8.svg",
  },
  {
    title: "Website v1 Launch",
    icon: "/images/icons/rocket-white.svg",
  },
  {
    title: "ProphetBot Live including feature",
    icon: "/images/Roadmap/5.svg",
  },
  {
    title: "Rich tiered access system",
    icon: "/images/Roadmap/3.svg",
  },
  {
    title: "Image Generation- ProphetBots Multi",
    icon: "/images/Roadmap/9.svg",
  },
  {
    title: "Wallet system",
    icon: "/images/Roadmap/10.svg",
  },
  {
    title: "Prophet Bot Sniper",
    icon: "/images/Roadmap/7.svg",
  },
  {
    title: "Revenue Generation for holders",
    icon: "/images/Roadmap/6.svg",
  },
  {
    title: "Partnerships",
    icon: "/images/Roadmap/11.svg",
  },
];

export const betaPhase = [
  {
    title: "$PROPHET Launch",
    icon: "/images/Roadmap/1.svg",
  },
  {
    title: "Image Generation",
    icon: "/images/Roadmap/2.svg",
  },
  {
    title: "Rich tiered access system",
    icon: "/images/Roadmap/3.svg",
  },
  {
    title: "Marketing",
    icon: "/images/Roadmap/4.svg",
  },
  {
    title: "ProphetBot Live including feature",
    icon: "/images/Roadmap/5.svg",
  },
  {
    title: "Revenue Generation for holders",
    icon: "/images/Roadmap/6.svg",
  },
  {
    title: "Prophet Bot Sniper",
    icon: "/images/Roadmap/7.svg",
  },
];

export const roadmap = [
  {
    id: 1,
    title: "Launch Phase",
    phases: [
      {
        id: 1,
        name: "Website Release",
        icon: {
          id: 10,
          name: "4.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "4_fdffb6bc49",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 6.92,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.544Z",
          updatedAt: "2023-08-15T12:24:54.544Z",
        },
      },
      {
        id: 2,
        name: "Gitbook Documentation",
        icon: {
          id: 13,
          name: "8.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "8_e637c5ce4f",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 7.58,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.698Z",
          updatedAt: "2023-08-15T12:24:54.698Z",
        },
      },
      {
        id: 3,
        name: "$PROPHET Launch",
        icon: {
          id: 18,
          name: "rocket-white.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "rocket_white_847d775774",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 5.15,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:26:42.828Z",
          updatedAt: "2023-08-15T12:26:42.828Z",
        },
      },
      {
        id: 4,
        name: "Manual Token Sniper",
        icon: {
          id: 8,
          name: "5.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "5_ffd268ce0d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 2.24,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.542Z",
          updatedAt: "2023-08-15T12:30:25.616Z",
        },
      },
      {
        id: 5,
        name: "AutoSniper",
        icon: {
          id: 7,
          name: "3.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "3_e331b73766",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 4.96,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.425Z",
          updatedAt: "2023-08-15T12:28:53.758Z",
        },
      },
      {
        id: 6,
        name: "Multi-Wallet Generation & System",
        icon: {
          id: 12,
          name: "9.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "9_ba77da915e",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 3.42,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.697Z",
          updatedAt: "2023-08-15T12:30:45.667Z",
        },
      },
      {
        id: 7,
        name: "P&L Analytical Image Generation",
        icon: {
          id: 15,
          name: "10.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "10_cee9753a2d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 4.05,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.722Z",
          updatedAt: "2023-08-15T12:27:32.417Z",
        },
      },
      {
        id: 8,
        name: "ProphetBots Perfected Signal Algorithms ",
        icon: {
          id: 11,
          name: "7.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "7_ac0e524a46",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 3.29,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.695Z",
          updatedAt: "2023-08-15T12:24:54.695Z",
        },
      },
      {
        id: 9,
        name: "Revenue Sharing",
        icon: {
          id: 9,
          name: "6.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "6_b25ced995d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 5.85,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.543Z",
          updatedAt: "2023-08-15T12:29:26.801Z",
        },
      },
      {
        id: 10,
        name: "Copytrader",
        icon: {
          id: 14,
          name: "11.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "11_325fa80bdf",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 4.06,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.718Z",
          updatedAt: "2023-08-15T12:24:54.718Z",
        },
      },
    ],
  },
  {
    id: 2,
    title: "Prophecy Phase",
    phases: [
      {
        id: 11,
        name: "Interactive Revenue Sharing Dashboard",
        icon: {
          id: 5,
          name: "1.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "1_4f9bd02110",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 1.51,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.423Z",
          updatedAt: "2023-08-15T12:30:09.511Z",
        },
      },
      {
        id: 12,
        name: "Referral System",
        icon: {
          id: 12,
          name: "9.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "9_ba77da915e",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 3.42,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.697Z",
          updatedAt: "2023-08-15T12:30:45.667Z",
        },
      },
      {
        id: 13,
        name: "Signal Ratings",
        icon: {
          id: 7,
          name: "3.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "3_e331b73766",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 4.96,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.425Z",
          updatedAt: "2023-08-15T12:28:53.758Z",
        },
      },
      {
        id: 14,
        name: "Extreme Machine Learnt AutoSniper",
        icon: {
          id: 10,
          name: "4.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "4_fdffb6bc49",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 6.92,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.544Z",
          updatedAt: "2023-08-15T12:24:54.544Z",
        },
      },
      {
        id: 15,
        name: "Algorithm Backtesting Improvements ",
        icon: {
          id: 8,
          name: "5.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "5_ffd268ce0d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 2.24,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.542Z",
          updatedAt: "2023-08-15T12:30:25.616Z",
        },
      },
      {
        id: 16,
        name: "Presale Sniper",
        icon: {
          id: 9,
          name: "6.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "6_b25ced995d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 5.85,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.543Z",
          updatedAt: "2023-08-15T12:29:26.801Z",
        },
      },
      {
        id: 17,
        name: "Strategic Partnerships",
        icon: {
          id: 11,
          name: "7.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "7_ac0e524a46",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 3.29,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.695Z",
          updatedAt: "2023-08-15T12:24:54.695Z",
        },
      },
      {
        id: 27,
        name: "Limit Orders",
        icon: {
          id: 15,
          name: "10.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "10_cee9753a2d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 4.05,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.722Z",
          updatedAt: "2023-08-15T12:27:32.417Z",
        },
      },
    ],
  },
  {
    id: 3,
    title: "Moon Phase",
    phases: [
      {
        id: 18,
        name: "Additional Profit Driven User Rewards",
        icon: {
          id: 10,
          name: "4.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "4_fdffb6bc49",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 6.92,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.544Z",
          updatedAt: "2023-08-15T12:24:54.544Z",
        },
      },
      {
        id: 19,
        name: "ProphetBot 2.0",
        icon: {
          id: 5,
          name: "1.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "1_4f9bd02110",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 1.51,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.423Z",
          updatedAt: "2023-08-15T12:30:09.511Z",
        },
      },
      {
        id: 20,
        name: "Moonshot Mode",
        icon: {
          id: 8,
          name: "5.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "5_ffd268ce0d",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 2.24,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.542Z",
          updatedAt: "2023-08-15T12:30:25.616Z",
        },
      },
      {
        id: 21,
        name: "Automatic Burn Integration",
        icon: {
          id: 7,
          name: "3.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 18,
          formats: null,
          hash: "3_e331b73766",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 4.96,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.425Z",
          updatedAt: "2023-08-15T12:28:53.758Z",
        },
      },
      {
        id: 22,
        name: "Multi-Chain",
        icon: {
          id: 12,
          name: "9.svg",
          alternativeText: null,
          caption: null,
          width: 18,
          height: 19,
          formats: null,
          hash: "9_ba77da915e",
          ext: ".svg",
          mime: "image/svg+xml",
          size: 3.42,
          url: "/images/Roadmap/1.svg",
          previewUrl: null,
          provider: "local",
          provider_metadata: null,
          folderPath: "/",
          createdAt: "2023-08-15T12:24:54.697Z",
          updatedAt: "2023-08-15T12:30:45.667Z",
        },
      },
    ],
  },
];

export const features = [
  {
    id: 8,
    title: "Free Access",
    text: "Manual Token Sniper, Anti Rug, Mev Protection, Private Transactions, Image Generated P&L Analysis, 1% Fees",
    isFree: true,
    img: {
      id: 21,
      name: "slide4.png",
      alternativeText: null,
      caption: null,
      width: 370,
      height: 427,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/images/thumbnail_slide4_d41a54a831.png",
          hash: "thumbnail_slide4_d41a54a831",
          mime: "image/png",
          name: "thumbnail_slide4.png",
          path: null,
          size: 22.59,
          width: 135,
          height: 156,
        },
      },
      hash: "slide4_d41a54a831",
      ext: ".png",
      mime: "image/png",
      size: 22.36,
      url: "/images/Features/slide4.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.247Z",
      updatedAt: "2023-08-15T13:30:10.247Z",
    },
  },
  {
    id: 7,
    title: "1000 $PROPHET",
    text: "Manual Token Sniper, Anti Rug, Mev Protection Private Transactions, Image Generated P&L Analysis, ProphetBots AutoSniper, Multi-Wallet Trading, ProphetBots Algorithm Signals, Fresh Wallet Mode, Copy Trader, 1% Fees",
    isFree: false,
    img: {
      id: 22,
      name: "slide3.png",
      alternativeText: null,
      caption: null,
      width: 1084,
      height: 774,
      formats: {
        large: {
          ext: ".png",
          url: "/images/Features/slide3.png",
          hash: "large_slide3_e64f84b972",
          mime: "image/png",
          name: "large_slide3.png",
          path: null,
          size: 599.7,
          width: 1000,
          height: 714,
        },
        small: {
          ext: ".png",
          url: "/images/Features/slide1.png",
          hash: "small_slide3_e64f84b972",
          mime: "image/png",
          name: "small_slide3.png",
          path: null,
          size: 171.49,
          width: 500,
          height: 357,
        },
        medium: {
          ext: ".png",
          url: "/images/medium_slide3_e64f84b972.png",
          hash: "medium_slide3_e64f84b972",
          mime: "image/png",
          name: "medium_slide3.png",
          path: null,
          size: 358.5,
          width: 750,
          height: 536,
        },
        thumbnail: {
          ext: ".png",
          url: "/images/thumbnail_slide3_e64f84b972.png",
          hash: "thumbnail_slide3_e64f84b972",
          mime: "image/png",
          name: "thumbnail_slide3.png",
          path: null,
          size: 45.82,
          width: 218,
          height: 156,
        },
      },
      hash: "slide3_e64f84b972",
      ext: ".png",
      mime: "image/png",
      size: 122.17,
      url: "/images/Features/slide3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.517Z",
      updatedAt: "2023-08-15T13:30:10.517Z",
    },
  },
  {
    id: 6,
    title: "2000 $PROPHET",
    text: "Manual Token Sniper, Anti Rug, Mev Protection Private Transactions, Image Generated P&L Analysis, ProphetBots AutoSniper, Multi-Wallet Trading, ProphetBots Algorithm Signals, Fresh Wallet Mode, Copy Trader, Big Brain Mode, Early Bird Mode, Revenue Share, 1% Fees",
    isFree: false,
    img: {
      id: 19,
      name: "slide2.png",
      alternativeText: null,
      caption: null,
      width: 344,
      height: 366,
      formats: {
        thumbnail: {
          ext: ".png",
          url: "/images/Features/slide2.png",
          hash: "thumbnail_slide2_1a0c659f6d",
          mime: "image/png",
          name: "thumbnail_slide2.png",
          path: null,
          size: 29.69,
          width: 147,
          height: 156,
        },
      },
      hash: "slide2_1a0c659f6d",
      ext: ".png",
      mime: "image/png",
      size: 22.68,
      url: "/images/Features/slide2.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.030Z",
      updatedAt: "2023-08-15T13:30:10.030Z",
    },
  },

  {
    id: 5,
    title: "3000 $PROPHET",
    text: "Manual Token Sniper, Anti Rug, Mev Protection Private Transactions, Image Generated P&L Analysis, ProphetBots AutoSniper, Multi-Wallet Trading, ProphetBots Algorithm Signals, Fresh Wallet Mode, Copy Trader, Big Brain Mode, Early Bird Mode, God Mode, Revenue Share, 0.5% Fees",
    isFree: false,
    img: {
      id: 20,
      name: "slide1.png",
      alternativeText: null,
      caption: null,
      width: 614,
      height: 536,
      formats: {
        small: {
          ext: ".png",
          url: "/images/Features/slide1.png",
          hash: "small_slide1_c81b90bb64",
          mime: "image/png",
          name: "small_slide1.png",
          path: null,
          size: 92.53,
          width: 500,
          height: 436,
        },
        thumbnail: {
          ext: ".png",
          url: "/images/Features/slide1.png",
          hash: "thumbnail_slide1_c81b90bb64",
          mime: "image/png",
          name: "thumbnail_slide1.png",
          path: null,
          size: 18.82,
          width: 179,
          height: 156,
        },
      },
      hash: "slide1_c81b90bb64",
      ext: ".png",
      mime: "image/png",
      size: 26.3,
      url: "/images/Features/slide1.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T13:30:10.144Z",
      updatedAt: "2023-08-15T13:30:10.144Z",
    },
  },
];

export const tokenomics = [
  {
    id: 1,
    value: "1000000",
    text: "Max Supply",
    img: {
      id: 2,
      name: "1.png",
      alternativeText: null,
      caption: null,
      width: 100,
      height: 100,
      formats: null,
      hash: "1_980b21f019",
      ext: ".png",
      mime: "image/png",
      size: 4.04,
      url: "/images/Features/1.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T11:52:46.529Z",
      updatedAt: "2023-08-15T11:52:46.529Z",
    },
  },
  {
    id: 2,
    value: "5%",
    text: "Buy/Sell Tax",
    img: {
      id: 3,
      name: "2.png",
      alternativeText: null,
      caption: null,
      width: 100,
      height: 100,
      formats: null,
      hash: "2_b34235dad6",
      ext: ".png",
      mime: "image/png",
      size: 2.33,
      url: "/images/Features/2.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T11:53:30.889Z",
      updatedAt: "2023-08-15T11:53:30.889Z",
    },
  },
  {
    id: 3,
    value: "1%",
    text: "Max Bag",
    img: {
      id: 4,
      name: "3.png",
      alternativeText: null,
      caption: null,
      width: 100,
      height: 100,
      formats: null,
      hash: "3_87f90f3fe1",
      ext: ".png",
      mime: "image/png",
      size: 2.83,
      url: "/images/Features/3.png",
      previewUrl: null,
      provider: "local",
      provider_metadata: null,
      folderPath: "/",
      createdAt: "2023-08-15T11:53:59.443Z",
      updatedAt: "2023-08-15T11:53:59.443Z",
    },
  },
];
